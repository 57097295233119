import {
  Card,
  CardBody,
  Typography,
  IconButton,
} from "@material-tailwind/react";

export function FeatureCard({ color, icon, title, description, feature }) {
  return (
    <Card className={feature? "other-services-card rounded-2xl bg-white shadow-xl shadow-gray-500/20" : "features rounded-2xl bg-white shadow-xl shadow-gray-500/20"}>
      <CardBody className="px-8 text-center">
        <IconButton
          variant="gradient"
          size="lg"
          color={color}
          className="features-icon pointer-events-none mb-6 rounded-full"
        >
          {icon}
        </IconButton>
        <Typography variant="h5" className="mb-2 aos-init" data-aos="fade-down" color="blue-gray">
          {title}
        </Typography>
        {description && (
        <Typography className="font-normal text-blue-gray-600 aos-init" data-aos="fade-up">
          {description}
        </Typography>
        )}
      </CardBody>
    </Card>
  );
}


export default FeatureCard;
