import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

const Bannar = () => {
    const { t } = useTranslation();
    return (
        <section className="bannar">
            <div className="relative z-[1] flex content-center items-center justify-center py-36">
                <div className="absolute z-[-1] top-0 h-full w-full bg-fixed bg-[url('https://www.lawnow.org/wp-content/uploads/2021/11/pexels-ekaterina-bolovtsova-6077326.jpg')] bg-cover bg-center" />
                <div className="absolute z-[-1] top-0 h-full w-full bg-[#9b6a13]/50 bg-cover bg-center" />
                <div className="absolute z-[-1] top-0 h-full w-full bannar-cover z-10 bg-cover " />
                <div className="max-w-8xl container relative mx-auto">
                    <div className="flex flex-wrap items-center">
                        <div className="ml-auto mr-auto w-full px-4 text-center lg:w-8/12">
                            <Typography
                                variant="h3"
                                color="white"
                            >
                                {t('bannar-t1')}
                            </Typography>
                            <Typography
                                variant="h1"
                                color="white"
                                className="mb-3 font-black"
                            >
                                {t('bannar-t2')}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Bannar;