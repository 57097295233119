import { Typography } from "@material-tailwind/react";

export function PageTitle({ heading, children }) {
  return (
    <div className="mx-auto w-full px-4 text-center lg:w-6/12">
      <Typography variant="h3" className="mb-3 text-[#9b6a13]">
        {heading}
      </Typography>
      {children && (
      <Typography variant="lead" className="text-blue-gray-700">
        {children}
      </Typography>
      )}
    </div>
  );
}


export default PageTitle;
