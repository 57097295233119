import {Routes, Route} from "react-router-dom";
import Home from "./Pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useContext } from "react";
import { langContext } from "./context/LangContext";

function App() {
  const {lng} = useContext(langContext)
  return (  
    <div className={lng === 'en'? "App en" : "App"}>
    <div className="bg-[url('https://static.vecteezy.com/system/resources/thumbnails/000/358/468/small/White_Background-01.jpg')] bg-center">
      {/* <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4"> */}
      <div className="left-0 top-0 w-full bg-white absolute md:relative z-10 mx-auto ">
        <Navbar />
      </div>

      <Routes>   
        <Route path="/" element={<Home />} />
      </Routes>

      <div className="bg-blue-gray-50/50">
        <Footer />
      </div>
    </div>
    </div>
  );
}

export default App;
