import React, { useContext, useEffect } from "react";
import { Link } from "react-scroll";
import {
  Navbar as MTNavbar,
  Collapse,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  HomeIcon,
  UsersIcon,
  Squares2X2Icon,
  ChatBubbleOvalLeftEllipsisIcon
} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { langContext } from "../context/LangContext";

export function Navbar({ action }) {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 720 && setOpenNav(false)
    );
  }, []);

  const { t , i18n } = useTranslation();
  const {lng, setLng} = useContext(langContext);
  React.useEffect(() => {
    i18n.changeLanguage(lng);
  }, [lng]);

  const routes = [
    {
      icon: HomeIcon,
      name: t('nav-1'),
      path: "/",
    },
    {
      icon: UsersIcon,
      name: t('nav-2'),
      path: "about-section",
    },
    {
      icon: Squares2X2Icon,
      name: t('nav-3'),
      path: "services-section",
    },
    {
      icon: ChatBubbleOvalLeftEllipsisIcon,
      name: t('nav-4'),
      path: "contact-section",
    },
  ];

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 text-inherit md:mb-0 md:mt-0 md:flex-row md:items-center md:gap-6">
      {routes.map(({ name, path, icon }) => (
        <Typography
          key={name}
          as="li"
          // variant="small"
          color="black"
          className="capitalize"
          >
            <Link
              to={path}
              spy={true} 
              smooth={true}
              offset={50} 
              duration={500}
              onClick={() => setOpenNav(false)}
              
              className="flex items-center gap-1 p-1 font-normal text-lg cursor-pointer"
            >
              {icon &&
                React.createElement(icon, {
                  className: "w-[18px] h-[18px] text-[#c98510] opacity-75 mr-1",
                })}
              {name}
            </Link>
    </Typography>
      ))}
    </ul>
  );

  return (
    <MTNavbar color="white" className="p-2 h-20 mx-auto rounded-none shadow-none bg-white w-full opacity-100 max-w-full">
      <div className="mx-auto flex items-center justify-between text-white">
        <a href="/">
          <img src={require('../assets/images/1.png')} width={200} alt="logo" />
        </a>
        <div className="hidden md:block">{navList}</div>
        <div className="hidden gap-2 md:flex">
      <Button size="sm" 
      onClick={()=> setLng(lng === 'en'? 'ar' : 'en')}
      className="hidden md:inline-block w-full block px-4 rounded-full bg-[#c98510] hover:shadow-[#c98510]/75 shadow-none" fullWidth>
        {t('lang')}
      </Button>
        </div>
        <IconButton
          variant="text"
          size="sm"
          className="bg-[#c98510] text-inherit  md:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon strokeWidth={2} className="h-6 w-6" />
          ) : (
            <Bars3Icon strokeWidth={2} className="h-6 w-6" />
          )}
        </IconButton>
      </div>
      <Collapse
        className="mt-4 shadow-xl rounded-xl bg-white px-4 text-blue-gray-900"
        open={openNav}
      >
        <div className="container pt-2 pb-4 mx-auto">
          {navList}
      <Button 
      size="sm"
      onClick={()=> setLng(lng === 'en'? 'ar' : 'en')}
      className="w-full block px-4 rounded-full bg-[#c98510] hover:shadow-[#c98510]/75 shadow-none" fullWidth>
      {t('lang')}
      </Button>
        </div>
      </Collapse>
    </MTNavbar>
  );
}


   


export default Navbar;
