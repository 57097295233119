import { Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

const year = new Date().getFullYear();

export function Footer() {
  const {t} = useTranslation();
  return (
    <footer className="relative z-[1] px-4 pt-8 pb-6 bg-[#301f14]">
      <div className="absolute z-[-1] top-0 h-full w-full footer-cover bg-[560px] " />
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row py-6 text-center xl:text-right footer-contain">
          <div className="px-4 ">
           <img src={require('../assets/images/white-logo@2x.png')} className="inline mx-auto xl:ms-auto"  width={300} alt="logo" />
            <Typography className="font-normal text-gray-300 mt-4 xl:max-w-[360px]">
            {t('footer-p1')}
            </Typography>
            {/* <div className="mx-auto mt-6 mb-8 flex justify-center gap-2 md:mb-0 lg:justify-start">
              {socials.map(({ color, name, path }) => (
                <a
                  key={name}
                  href={path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton color="white" className="rounded-full">
                    <Typography color={color}>
                      <i className={`fa-brands fa-${name}`} />
                    </Typography>
                  </IconButton>
                </a>
              ))}
            </div> */}
          </div>
          <div className="mx-auto mt-12 grid w-max sm:grid-cols-2 gap-16 xl:mt-0">
              <div>
                <Typography
                  variant="small"
                  className="mb-2 text-[#9b6a13] block font-medium uppercase"
                >
                  {t('footer-t1')}
                </Typography>
                <ul className="mt-3">
                      <li>
                      <Typography
                        as="a"
                        target="_blank"
                        rel="noreferrer"
                        variant="small"
                        className="mb-2 block font-normal text-gray-300 hover:text-white"
                      >
                       {t('footer-p2')}
                      </Typography>
                    </li>
                      <li>
                      <Typography
                        as="a"
                        target="_blank"
                        rel="noreferrer"
                        variant="small"
                        className="mb-2 block font-normal text-gray-300 hover:text-white"
                      >
                        {t('footer-p3')}
                      </Typography>
                    </li>
                      <li>
                      <Typography
                        as="a"
                        target="_blank"
                        rel="noreferrer"
                        variant="small"
                        className="mb-2 block font-normal text-gray-300 hover:text-white"
                      >
                        {t('footer-p4')}
                      </Typography>
                    </li>
                </ul>
              </div>
              <div>
              <Typography
                  variant="small"
                  className="mb-2 text-[#9b6a13] block font-medium uppercase"
                >
                  {t('footer-t2')}
                </Typography>
                <ul className="mt-3">
                      <li>
                      <Typography
                        as="a"
                        target="_blank"
                        rel="noreferrer"
                        variant="small"
                        className="mb-2 block font-normal text-gray-300 hover:text-white"
                      >
                        0555959312 -
                        0555959328  <br />
                        0555959347 -
                        0138177771
                      </Typography>
                    </li>
                      <li>
                      <Typography
                        as="a"
                        target="_blank"
                        rel="noreferrer"
                        variant="small"
                        className="mb-2 block font-normal text-gray-300 hover:text-white"
                      >
                        al7sanilegal@gmail.com
                      </Typography>
                    </li>
                    </ul>
                    <div className="flex flex-row justify-center md:justify-start gap-x-2">
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/al7sanilegal"><i className='bx bxl-facebook text-white text-[24px]'></i></a>
                    <a target="_blank" rel="noreferrer" href="https://www.twitter.com/al7sanilegal"><i className='bx bxl-twitter text-white text-[24px]'></i></a>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/al7sanilegal"><i className='bx bxl-instagram text-white text-[24px]'></i></a>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/al7sanilegal"><i className='bx bxl-linkedin text-white text-[24px]'></i></a>
                    </div>
              </div>
          </div>
        </div>
        <hr className="my-6 border-white/10" />
        <div className="flex flex-wrap items-center justify-center md:justify-between">
            <div className="w-full text-center mx-auto px-4">
            <Typography
              variant="small"
              className="font-normal text-gray-300"
            >
              {t('footer-p8')} © {year} {t('footer-p9')} <span className="px-4">|</span> <a href="https://www.dataked.com" target="_blank" rel="noreferrer" >{t('footer-p10')} Dataked</a>
            </Typography>
            </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
