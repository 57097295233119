import React, { useDebugValue, useEffect, useState } from "react";
import {
    Card,
    Typography,
    Button,
    Input,
    Textarea,
} from "@material-tailwind/react";
import { UsersIcon } from "@heroicons/react/24/solid";
import { FeatureCard } from "../components/FeatureCard";
import PageTitle from "../components/PageTitle";
import {
    StarIcon,
    HeartIcon,
    FingerPrintIcon,
    Squares2X2Icon,
    ChatBubbleOvalLeftEllipsisIcon,
    CheckBadgeIcon
} from "@heroicons/react/24/solid";
import Bannar from "../components/Bannar";
import Icon1 from "../assets/images/c1@2x.png";
import Icon2 from "../assets/images/c2@2x.png";
import Icon3 from "../assets/images/c3@2x.png";
import Icon4 from "../assets/images/c4@2x.png";
import Icon6 from "../assets/images/c6@2x.png";
import { useTranslation } from "react-i18next";
import axios from "axios";
export function Home() {
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState(0);


    const featuresData = [
        {
            color: "blue",
            title: t('about-t1'),
            icon: StarIcon,
            description: t("about-p1"),
        },
        {
            color: "red",
            title: t("about-t2"),
            icon: HeartIcon,
            description: t("about-p2"),
        },
        {
            color: "yellow",
            title: t("about-t3"),
            icon: FingerPrintIcon,
            description: t('about-p3'),
        },
    ];
    const contactData = [
        {
            title: t('services-t2'),
            icon: Icon4,
            description: t('services-t2p')
        },
        {
            title: t('services-t3'),
            icon: Icon4,
            description: t('services-t3p'),
        },
        {
            title: t('services-t4'),
            icon: Icon3,
            description: t('services-t4p'),
        },
        {
            title: t('services-t5'),
            icon: Icon3,
            description: t('services-t5p'),
        },
        {
            title: t('services-t6'),
            icon: Icon1,
            description: t('services-t6p'),
        },
        {
            title: t('services-t7'),
            icon: Icon6,
            description: t('services-t7p'),
        }
    ];

    const otherServices = [
        {
            color: "yellow",
            title: t('services-t11'),
            icon: CheckBadgeIcon,
        },
        {
            color: "gray",
            title: t('services-t12'),
            icon: CheckBadgeIcon,
        },
        {
            color: "orange",
            title: t('services-t13'),
            icon: CheckBadgeIcon,
        },
        {
            color: "green",
            title: t('services-t14'),
            icon: CheckBadgeIcon,
        },
        {
            color: "red",
            title: t('services-t15'),
            icon: CheckBadgeIcon,
        },
        {
            color: "blue",
            title: t('services-t16'),
            icon: CheckBadgeIcon,
        },
        {
            color: "teal",
            title: t('services-t17'),
            icon: CheckBadgeIcon,
        },
        {
            color: "purple",
            title: t('services-t18'),
            icon: CheckBadgeIcon,
        },
        {
            color: "brown",
            title: t('services-t19'),
            icon: CheckBadgeIcon,
        },
        {
            color: "pink",
            title: t('services-t20'),
            icon: CheckBadgeIcon,
        },

    ]

    const slides = [
        { text: t('hero-p1') },
        { text: t('hero-p2') },
        { text: t('hero-p3') },
        { text: t('hero-p4') },
    ]

    useEffect(() => {
        setTimeout(() => {
            sliderMove();
        }, 2500);
    });

    const sliderMove = (sliderIndex) => {
        setCurrentIndex(currentIndex === slides.length - 1 ? 0 : currentIndex + 1);
    }

    const [name, setName] = useState('');
    const [body, setBody] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');


    const submitForm = async (e) => {
        e.preventDefault();
        axios.post('http://offer.dataked.com/api/send', {
            name, 
            body, 
            email, 
            phone
          })
          .then(function (response) {
            console.log(response);
            setSuccess(t('contact-p6'))
          })
          .catch(function (error) {
            console.log(error);
            setError(t('contact-p7'))
          });
        // try{
        //     await fetch("http://offer.dataked.com/api/send", {
        //     method: "POST",
        //     headers: { "Content-type": "application/json" },
        //     body: JSON.stringify({ name, body, email, phone })
        // });
        // setSuccess(t('contact-p6'))
        // } catch(err){
        //     console.log(err)
        //     setError(t('contact-p7'))
        // }

    }
    return (
        <>
            <div className="relative flex h-screen content-center items-center justify-center pt-16 pb-32">
                <div className="absolute top-0 h-full w-full bg-[url('https://www.nerdynaut.com/wp-content/uploads/2021/08/Are-You-Looking-For-Legal-Advice-Here-Are-4-Ways-To-Find-It.jpg')] bg-cover bg-center" />
                <div className="absolute top-0 h-full w-full bg-[#9b6a13]/70 bg-cover bg-center" />
                <div className="max-w-8xl container relative mx-auto">
                    <div className="flex flex-wrap items-center">
                        <div className="ml-auto mr-auto w-full px-4 text-center lg:w-8/12">
                            <Typography
                                variant="h4"
                                color="white"
                                className="bg-[#301f14]/75  text-[20px] sm:text-[23px] lg:text-[30px] xl:text-[35px] 2xl:text-[40px] inline px-2 rounded-xl"
                            >
                                {t('hero-t1')}
                            </Typography>
                            <Typography
                                variant="h1"
                                color="white"
                                className="mb-2 sm:mb-5 font-black text-[38px] lg:text-[45px] xl:text-[55px] 2xl:text-[65px]"
                            >
                                {t('hero-t2')}
                            </Typography>
                            <div className="flex justify-center align-center mb-10">
                                {slides.map((slide, slideIndex) => (
                                    <Typography color="white" className={slideIndex === currentIndex ? "opacity-1 font-light text-lg sm:text-[20px] md:text-[25px] 2xl:text-[30px] slider-caption scale-[1.2] max-w-[260px] sm:max-w-[400px]" : "opacity-0 font-light text-lg sm:text-[20px] md:text-[25px] 2xl:text-[30px] slider-caption scale-100 max-w-[260px] sm:max-w-[400px]"} key={slide.text}>
                                        {slide.text}
                                    </Typography>
                                ))}
                            </div>
                            {/* 
                        <div className="flex justify-center gap-x-2 my-2">
                    {slides.map((slide, slideIndex) => (
                    <div className={slideIndex === currentIndex? "" : "cursor-pointer"} key={slide.text} onClick={()=> setCurrentIndex(slideIndex)}>
                        {slideIndex === currentIndex? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" style={{fill: "rgba(255, 255, 255, 1)"}}><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2z"></path></svg>
                        ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" style={{fill: "rgba(255, 255, 255, .6)"}}><path d="M12 2C6.486 2 2 6.486 2 12c.001 5.515 4.487 10.001 10 10.001 5.514 0 10-4.486 10.001-10.001 0-5.514-4.486-10-10.001-10zm0 18.001c-4.41 0-7.999-3.589-8-8.001 0-4.411 3.589-8 8-8 4.412 0 8.001 3.589 8.001 8-.001 4.412-3.59 8.001-8.001 8.001z"></path></svg>
                        )}
                    </div>
                    ))} 
                    </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <section className="-mt-32  px-4 pb-24 pt-4">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                        {featuresData.map(({ color, title, icon, description }) => (
                            <FeatureCard
                                key={title}
                                color={color}
                                title={title}
                                className="aos-init"
                                data-aos="fade-up"
                                icon={React.createElement(icon, {
                                    className: "w-5 h-5 text-white",
                                })}
                                description={description}
                            />
                        ))}
                    </div>

                    {/* About section  */}

                    <div className="relative pt-24 flex flex-wrap items-center" id="about-section">
                        <div className="mx-auto -mt-8 w-full px-4 md:w-5/12">
                            <div className="aos-init" data-aos="fade-down">
                                <div className="mb-6 inline-flex h-16 w-16 items-center justify-center rounded-full bg-[#9b6a13] p-3 text-center shadow-lg">
                                    <UsersIcon className="h-6 w-6 text-white " />
                                </div>
                                <Typography
                                    variant="h3"
                                    className="mb-3 font-bold text-[#9b6a13]"
                                >
                                    {t('about-t4')}
                                </Typography>
                            </div>
                            <Typography className="mb-8 font-normal text-blue-gray-700 aos-init" data-aos="fade-left">
                                {t('about-p4')}
                                <br />
                                <br />
                                {t('about-p5') && t('about-p5')}
                            </Typography>
                        </div>
                        <div className="relative z-[1] about-cover-container mx-auto mt-24 flex w-full h-[500px] justify-center px-4 md:w-4/12 lg:mt-0 aos-init" data-aos="fade-right">
                            <div className="absolute z-[-1] top-0 h-full w-full about-cover-img bg-[url('https://financialandlegalconsultant.com/wp-content/uploads/2020/06/FL1-scaled.jpg')] bg-cover bg-center" />
                            {/* <div className="absolute z-[-1] top-0 h-full w-full about-cover z-10 bg-[560px] " /> */}
                            <div className="absolute z-[-1] h-full w-[80%] about-cover-logo bg-contain  z-5 " />
                        </div>
                    </div>
                </div>
            </section>


            {/* Services section  */}


            <section className="relative py-24 px-4" id="services-section">
                <div className="container mx-auto">
                    <div className="aos-init" data-aos="fade-up">
                        <div className="mb-6 mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-[#9b6a13] p-3 text-center shadow-lg">
                            <Squares2X2Icon className="h-6 w-6 text-white " />
                        </div>
                        <PageTitle heading={t('services-t1')}>
                            {t('services-t1p')}
                        </PageTitle>
                    </div>
                    <div className="mx-auto mt-20 mb-16 grid max-w-5xl grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
                        {contactData.map(({ title, icon, description }) => (
                            <Card
                                key={title}
                                color="transparent"
                                shadow={false}
                                className="service-card relative z-[1] text-center text-blue-gray-900 bg-[#9b6a13] p-4 aos-init"
                                data-aos="fade-down"
                            >
                                <div className="absolute z-[-1] top-0 h-full w-full services-card-cover z-10 bg-[220px] opacity-[0.15]" />
                                <div className="mx-auto mb-6 grid h-20 w-20 p-1 place-items-center rounded-full bg-white shadow-lg shadow-gray-500/20">
                                    <img src={icon} alt="service-icon" />
                                </div>
                                <Typography variant="h5" color="white" className="mb-2">
                                    {title}
                                </Typography>
                                <Typography className="font-normal text-gray-300">
                                    {description}
                                </Typography>
                            </Card>
                        ))}
                    </div>
                    <div className="mx-auto">
                        <Card
                            color="transparent"
                            shadow={false}
                            className="service-card relative z-[1] text-center text-blue-gray-900 bg-[#9b6a13] p-4 max-w-[1030px] mx-auto aos-init"
                            data-aos="fade-right"
                        >
                            <div className="absolute z-[-1] top-0 h-full w-full services-card-cover z-10 bg-[220px] opacity-[0.15]" />
                            <div className="mx-auto mb-6 grid h-20 w-20 p-1 place-items-center rounded-full bg-white shadow-lg shadow-gray-500/20">
                                <img src={Icon2} alt="service-icon" />
                            </div>
                            <Typography variant="h5" color="white" className="mb-2">
                                {t('services-t8')}
                            </Typography>
                            <Typography className="font-normal text-gray-200">
                                {t('services-t8p')}
                            </Typography>
                            <ul className="font-normal text-gray-300">
                                <li>- {t('services-t8li1')}</li>
                                <li>- {t('services-t8li2')}</li>
                                <li>- {t('services-t8li3')}</li>
                                <li>- {t('services-t8li4')}</li>
                                <li>- {t('services-t8li5')}</li>
                                <li>- {t('services-t8li6')} </li>
                                <li>- {t('services-t8li7')}</li>
                            </ul>
                        </Card>
                    </div>

                </div>
            </section>

            {/* Our solutions section  */}

            <section className=" px-4" id="solutions-section">
                <div className="container mx-auto">
                    <div className="mb-6 mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-[#9b6a13] p-3 text-center shadow-lg">
                        <Squares2X2Icon className="h-6 w-6 text-white " />
                    </div>
                    <PageTitle heading={t('services-t9')}>
                    </PageTitle>
                    <div className="relative z-[1] max-w-5xl mx-auto mt-10 mb-24 text-center rounded-2xl bg-[#9b6a13]">
                        <div className="absolute z-[-1] top-0 h-full w-full services-card-cover z-10 bg-[220px] opacity-[0.15]" />
                        <ol>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li1')}</li>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li2')}</li>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li3')}</li>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li4')}</li>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li5')}</li>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li6')}</li>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li7')}</li>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li8')}</li>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li9')}</li>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li10')}</li>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li11')}</li>
                            <li className="py-3 px-2 aos-init" data-aos="fade-left">{t('services-t9li12')}</li>
                        </ol>
                    </div>

                </div>
            </section>

            {/* Other services section  */}
            <section className="relative py-16 px-4" id="services-section">
                <div className="container-fluid mx-auto">
                    <div className="mb-6 mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-[#9b6a13] p-3 text-center shadow-lg">
                        <Squares2X2Icon className="h-6 w-6 text-white " />
                    </div>
                    <PageTitle heading={t('services-t10')}>
                    </PageTitle>
                    <div className="max-w-7xl mx-auto mt-10 mb-24 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
                        {otherServices.map(({ color, title, icon }) => (
                            <FeatureCard
                                key={title}
                                color={color}
                                title={title}
                                feature={true}
                                icon={React.createElement(icon, {
                                    className: "w-5 h-5 text-white",
                                })}
                            />
                        ))}
                    </div>
                </div>
            </section>


            {/* Bannar section */}
            `
            <Bannar />

            {/* Contact us section  */}
            <section className="py-24" id="contact-section">
                <div className="aos-init" data-aos="fade-up">
                    <div className="mb-6 mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-[#9b6a13] p-3 text-center shadow-lg">
                        <ChatBubbleOvalLeftEllipsisIcon className="h-6 w-6 text-white " />
                    </div>
                    <PageTitle heading={t('contact-t1')}>
                        {t('contact-t1p')}
                    </PageTitle>
                </div>
                <div className="container mx-auto flex flex-col lg:flex-row justify-center align-center min-h-[450px] max-w-[1100px] rounded-xl overflow-hidden mt-20 shadow-xl">
                    <div className="contact-form lg:w-[50%] min-h-[450px] flex justify-center items-center bg-white p-6 aos-init" data-aos="fade-left">
                        <form
                            onSubmit={submitForm}
                            className="php-email-form mx-auto max-w-3xl w-full text-center">
                            <div className="mb-5 flex flex-col md:flex-row gap-x-8 gap-y-5">
                                <Input variant="standard" size="lg" name="name" value={name} required onChange={e => setName(e.target.value)} label={t('contact-p1')} />
                                <Input variant="standard" size="lg" name="email" value={email} required onChange={e => setEmail(e.target.value)} label={t('contact-p2')} />
                            </div>
                            <div className="mb-5">
                                <Input variant="standard" size="lg" type="phone" name="phone" value={phone} onChange={e => setPhone(e.target.value)} label={t('contact-p5')} />
                            </div>
                            <Textarea variant="standard" size="lg" name="body" value={body} required onChange={e => setBody(e.target.value)} label={t('contact-p3')} rows={8} />
                            <div className="my-3">
                                <div className="loading">تحميل</div>
                                { error && <div className="error-message">{error}</div>}
                                { success && <div className="sent-message">{success}</div>}
                            </div>
                            <Button type="submit" size="lg" className="mt-8 px-8 py-3 rounded-full bg-[#c98510] hover:shadow-[#c98510]/75 shadow-none">
                                {t('contact-p4')}
                            </Button>
                        </form>
                    </div>
                    <div className="contact-map lg:w-[50%] aos-init" data-aos="fade-right">
                        <div className="gmap_canvas">
                            <iframe className="w-full h-[450px]" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3573.9745505924625!2d50.10205787487714!3d26.392009832274592!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49e56d17dd0d3b%3A0xaa0edb74e82c2da8!2z2YXZg9iq2Kgg2KfZhNmF2K3Yp9mF2Yog2LPZhNin2YXZhyDYudix2YHYrCDYp9mE2K3Ys9mG2Yog2YTZhNmF2K3Yp9mF2KfYqSDZiNin2YTYp9iz2KrYtNin2LHYp9iqINin2YTZgtin2YbZiNmG2YrYqSDZiNin2YTYqtmI2KvZitmCINmI2KfZhNiq2K3Zg9mK2YU!5e0!3m2!1sen!2seg!4v1702389016491!5m2!1sen!2seg" title="office location" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
